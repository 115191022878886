import { graphql, useStaticQuery } from 'gatsby'

export const useAllContactDetails = () => {
  const { wordpressAcfOptions } = useStaticQuery(
    graphql`
      {
        wordpressAcfOptions {
          options {
            locations {
              locationName
              addressDisplay
              email
              phone
            }
          }
        }
      }
    `
  )
  return wordpressAcfOptions.options
}
