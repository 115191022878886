import React from 'react';
//import { useStaticQuery, graphql } from 'gatsby';
import LazyLoad from 'react-lazy-load'

const getImageUrl = (arrayString) => {
  var srcUrlPass = arrayString
  if ( typeof arrayString === 'string' ) return arrayString
  if ( srcUrlPass && srcUrlPass.url && srcUrlPass.url !== "" ) return srcUrlPass.url //Preview format
  if ( srcUrlPass && srcUrlPass.source_url && srcUrlPass.source_url !== "" ) return srcUrlPass.source_url //Passing URL
  return null
}

export const Image = (props) => {

  const { src, position = "absolute", className = null, alt, lazyLoad = false, returnBase64 = false, height, width, lazy = true, image = null } = props;

  if (src === undefined) return null

  const srcUrlPass = src && getImageUrl(src)

  if (srcUrlPass === null) return null
  /*
  const data = useStaticQuery(graphql`
    query ImageQuery {
      allWordpressWpMedia {
        nodes {
          source_url
          title
          localFile {
            publicURL
          }
        }
      }
    }
  `)
  const mediaEdge = data.allWordpressWpMedia.nodes
  let image = mediaEdge.filter(m => {
    return m.source_url === srcUrlPass;
  })
  */
  if (image && image[0]) {
    image = image[0]
    if (image && image.localFile && image.localFile.publicURL) {
      return (
        <div
          className={`gatsby-image gatsby-image-wrapper${className ? ` ${className}` : '' }`}
          style={{
             position: position,
             left: 0,
             top: 0,
             width: "100%",
             minHeight:"100%",
           }}
        >
          {lazy ?
            <LazyLoad><img src={image.localFile.publicURL} className={className} alt={image.title} /></LazyLoad>
          :
            <img src={image.localFile.publicURL} className={className} alt={image.title} />
          }

        </div>
      )
    }
  }

  //Preview Images
  return <div
    className={`gatsby-image gatsby-image-wrapper${className ? ` ${className}` : '' }`}
    style={{
       position: position,
       left: 0,
       top: 0,
       width: "100%",
       minHeight:"100%",
     }}
  >

    {lazy ?
      <LazyLoad><img src={srcUrlPass} className={`${className ? ` ${className}` : '' }`} alt={alt} /></LazyLoad>
    :
      <img src={srcUrlPass} className={`${className ? ` ${className}` : '' }`} alt={alt} />
    }
  </div>

  //Placeholder
  return (
    <div
      className={`gatsby-image placeholder${className ? ` ${className}` : ''}`}
      style={{
         position: position,
         left: 0,
         top: 0,
         width: "100%",
         height: "100%"
       }}
    />
  )
}
