import React, { Component, Fragment } from 'react';
import GatsbyLink from '../GatsbyLink';
import { ImagePass } from '../Image/ImagePass'
import { FaChevronDown } from '../../images/icons/FaChevronDown'
import { decodeEntities } from '../../utils/helpers';
import './HeaderNav.scss'
import LazyLoad from 'react-lazy-load'
const isClient = typeof window !== 'undefined';

const enableBodyScroll = () => {
  if (isClient) {
    document.body.classList.remove('nav-open');
  }
}

const returnCategory = (categoryNavigation, thisId) => {
  const thisCategory = categoryNavigation && categoryNavigation.edges.filter(category => category.node.wordpress_id === thisId)
  return thisCategory && thisCategory[0] && thisCategory[0].node
}

const NavItem = (props) => {
  const { item, baseUrl, openNavKey, toggleSubNav, categoryNavigation, handleLoadImages, loadImages, resetHoverClear, hoverClear } = props;
  const {
    slug,
    object_id,
    title,
    url,
    children,
    target,
    classes
  } = item;
  const urlPass = url === '/specials/' ? '#' : url
  const isOpen = openNavKey === object_id;

  return (
    <span
      key={object_id}
      //className={`link${children ? ' parent' : ''}`}
      className={`${hoverClear ? 'force ' : ''}`}
      onMouseOver={event => handleLoadImages(event)}
    >
      <GatsbyLink
        to={urlPass}
        onClick={enableBodyScroll}
        target={target ? target : null}
        className="parent"
        activeClassName="active"
        aria-label={title}
        decode={false}
        dangerouslySetInnerHTML={{__html: title}}
        partiallyActive={true}
        onClick={urlPass === '#' ? event => toggleSubNav(event, object_id) : event => resetHoverClear(event, true)}
      />
      {children && children[0].url !== '#gatsby' && (
        <>
          <NavChildren baseUrl={baseUrl} childNav={children} isOpen={isOpen} objectId={object_id} categoryNavigation={categoryNavigation} loadImages={loadImages} resetHoverClear={resetHoverClear} />
          <button
            type="button"
            className={`expand ${isOpen ? 'open' : 'closed'}`}
            onClick={event => toggleSubNav(event, object_id)}
            aria-label="Open Menu"
          >
            <FaChevronDown/>
          </button>
        </>
      )}
    </span>
  );
}

const NavChildren = (props) => {
  const { childNav, baseUrl, isOpen = null, objectId, categoryNavigation, loadImages, resetHoverClear } = props;
  const categoryContent = returnCategory(categoryNavigation, objectId);
  return (
    <div className={`children ${isOpen ? 'open' : 'close'}`}>
    <div className="wrap">
    {categoryContent && loadImages &&
      <div className="category-intro">
        {categoryContent.acf && <span className="h3">{categoryContent.acf.title}</span>}
        {categoryContent.acf && <div dangerouslySetInnerHTML={{__html: decodeEntities(categoryContent.acf.menuDescription)}} />}
      </div>
    }
    <ul className="menu-items">
      {childNav.map((child, index) => {
        const {
          classes,
          object_id,
          slug,
          title,
          url,
          target
        } = child;
        const categoryChildContent = returnCategory(categoryNavigation, object_id);
        return (
          <li
            key={index}
            className={classes}
            >
            <div className="child-link-wrap">
              <GatsbyLink
                to={url}
                onClick={enableBodyScroll}
                target={target ? target : null}
                partiallyActive={true}
                activeClassName="active"
                decode={false}
                aria-label={title}
                dangerouslySetInnerHTML={{__html: title}}
                onClick={event => resetHoverClear(event, true)}
              />
            </div>
            {categoryChildContent && loadImages &&
            <div className="category-child-content">
              {categoryChildContent.acf && <div className="feature-image"><div className="aspect"><ImagePass src={categoryChildContent.acf.featureImage} /></div></div>}
              <div className="feature-intro">
                {categoryChildContent.acf && <span className="h4">{categoryChildContent.acf.title}</span>}
                {categoryChildContent.acf && <div dangerouslySetInnerHTML={{__html: decodeEntities(categoryChildContent.acf.menuDescription)}}/>}
              </div>
            </div>
            }
          </li>
        )
      })}
    </ul>
    </div>
    </div>
  );
}

const SERVICES_TYPE = 'services-nav';
const COMMUNCIATIONS_TYPE = 'communication-nav';
const COMPANY_TYPE = 'company-nav';

export default class HeaderNav extends Component {
  state = {
    openNavKey: null,
    loadImages: null
  };

  componentDidMount() {
    if (isClient) {
      document.addEventListener('keydown', this.handleEscKey, false);
    }
  }

  componentWillUnmount() {
    if (isClient) {
      document.removeEventListener('keydown', this.handleEscKey, false);
    }
  }

  handleLoadImages = (event) => {
    const { resetHoverClear } = this.props
    if (!this.state.loadImage) {
      this.setState({loadImages: true})
    }
  }

  handleEscKey = (event) => {
    const { toggleNav, active } = this.props;
    if (event.keyCode === 27 && active) toggleNav(event);
  }

  toggleSubNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey });
  }

  render() {
    const { active, navItems, baseUrl, className, categoryNavigation, resetHoverClear, hoverClear } = this.props;
    const { openNavKey, loadImages } = this.state;
    return (

        <ul className={className}>
          {navItems && navItems.map((col, index) => index < 10 && (
            <li key={index} className={col.classes}>
              <NavItem key={col.url} item={col} baseUrl={baseUrl} toggleSubNav={this.toggleSubNav} handleLoadImages={this.handleLoadImages} loadImages={loadImages} openNavKey={openNavKey} categoryNavigation={categoryNavigation} resetHoverClear={resetHoverClear} hoverClear={hoverClear} />
            </li>
          ))}
        </ul>

    );
  }
}
