import React, { useState } from 'react'
import { useAllContactDetails } from '../../../hooks/useAllContactDetails'
import './MobilePhone.scss'

export const MobilePhone = () => {
  const [open, setOpen] = useState(false)

  const contactDetails = useAllContactDetails()
  return (
    <>
      <div
        className={`mobile-phone ${open ? 'mobile-phone--open' : ''}`}
        onClick={() => setOpen(current => !current)}
      ></div>
      <div
        className={`mobile-phone__menu ${
          open ? 'mobile-phone__menu--open' : ''
        }`}
      >
        {contactDetails?.locations &&
          Object.keys(contactDetails?.locations).length > 0 &&
          contactDetails?.locations?.map((location, index) => {
            return (
              <a
                href={`tel:${location.phone.replace(/[^A-Z0-9]/gi, '')}`}
                className="control-call"
                aria-label="Call Now"
                key={`mobile-phone__${index}`}
              >
                <strong>Call {location.locationName}</strong>
              </a>
            )
          })}
      </div>
    </>
  )
}
