import React, { useState, useEffect } from 'react'
import { useHeaderState } from './SideCartContext'
import './SideCart.scss'
import GatsbyLink from '../GatsbyLink'
import { Image } from '../Image'
import ls from 'local-storage'
import { useAllProducts } from '../../hooks/useAllProducts'

export const SideCart = ({ children }) => {
  const {
    dispatch,
    state: { menuOpen },
  } = useHeaderState()

  const [quote, setQuote] = useState(ls.get('quote'))

  const products = useAllProducts()

  // wow. No one copy this in the future. This is a hack to get the cart to update
  useEffect(() => {
    const interval = setInterval(() => {
      setQuote(ls.get('quote'))
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  const updateQty = (event, index, operation = null) => {
    event.preventDefault()
    const currentQuote = quote
    if (operation === 'add') {
      currentQuote[index].qty = parseInt(currentQuote[index].qty) + 1
    } else if (operation === 'sub') {
      currentQuote[index].qty = parseInt(currentQuote[index].qty) - 1
    }
    setQuote([...currentQuote])
    ls.set('quote', currentQuote)
  }

  const removeItem = (event, index) => {
    event.preventDefault()
    const currentQuote = quote
    currentQuote.splice(index, 1)
    setQuote([...currentQuote])
    ls.set('quote', currentQuote)
  }

  return (
    <>
      <div className={`side-cart ${menuOpen ? 'side-cart--open' : ''}`}>
        {/* SIDE CART HEADER */}
        <div className="side-cart__header">
          <p className="side-cart__heading">My Quote Cart</p>
          <svg
            width="64"
            height="64"
            viewBox="0 0 64 64"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            onClick={() =>
              dispatch({ type: 'setSideCartOpen', data: !menuOpen })
            }
            className="side-cart__close"
          >
            <path
              d="M45.1366 44.8618L18.4699 19.5284"
              stroke="currentColor"
              strokeWidth="3"
            />
            <path
              d="M18.4659 44.4469L45.1602 19.1426"
              stroke="currentColor"
              strokeWidth="3"
            />
          </svg>
        </div>

        {/* SIDE CART MAIN */}

        <div className="side-cart__main">
          {quote && (
            <ul className="side-cart__product-list">
              {Object.keys(quote).length > 0 &&
                quote?.map((quoteItem, index) => {
                  const itemDetails = products.edges.filter(
                    i => i.node.wordpress_id === quoteItem.productId
                  )
                  const itemImage =
                    quoteItem.options &&
                    quoteItem.options.colour &&
                    quoteItem.options.colour.colourImage &&
                    quoteItem.options.colour.colourImage.source_url
                      ? quoteItem.options.colour.colourImage
                      : itemDetails[0] && itemDetails[0].node.featured_image_url
                  return (
                    <li
                      key={`product__item__${index}`}
                      className="side-cart__product"
                    >
                      <div className="side-cart__product__image">
                        <GatsbyLink
                          to={itemDetails[0] && itemDetails[0].node.path}
                        >
                          <Image src={itemImage} lazy={false} />
                        </GatsbyLink>
                      </div>
                      <div className="side-cart__product__info">
                        <GatsbyLink
                          className="side-cart__product__info__title"
                          title="View product"
                          to={itemDetails[0] && itemDetails[0].node.path}
                          dangerouslySetInnerHTML={{
                            __html: itemDetails[0] && itemDetails[0].node.title,
                          }}
                        />
                        {quoteItem.options &&
                          quoteItem.options.colour &&
                          quoteItem.options.colour.colourHex && (
                            <div className="side-cart__product__info__colour">
                              Colour:{' '}
                              <div
                                title={quoteItem.options.colour.colourName}
                                key={index}
                                className="side-cart__product__info__colour__swatch"
                                style={{
                                  backgroundColor: quoteItem.options.colour
                                    .colourHex
                                    ? quoteItem.options.colour.colourHex
                                    : null,
                                }}
                              ></div>
                            </div>
                          )}
                        <div className="side-cart__product__info__qty">
                          <div
                            className="side-cart__product__info__qty__btn"
                            onClick={event => updateQty(event, index, 'sub')}
                            aria-label="Add one to quantity"
                          >
                            -
                          </div>
                          <div className="side-cart__product__info__qty__amount">
                            Qty: {quote[index].qty}
                          </div>
                          <div
                            className="side-cart__product__info__qty__btn"
                            onClick={event => updateQty(event, index, 'add')}
                            aria-label="Subtract one to quantity"
                          >
                            +
                          </div>
                        </div>
                        <div className="side-cart__product__info__links">
                          <GatsbyLink
                            to={itemDetails[0] && itemDetails[0].node.path}
                            onClick={() =>
                              dispatch({ type: 'setSideCartOpen', data: false })
                            }
                          >
                            Edit
                          </GatsbyLink>
                          <a onClick={event => removeItem(event, index)}>
                            Remove from cart
                          </a>
                        </div>
                      </div>
                    </li>
                  )
                })}
            </ul>
          )}
        </div>

        {/* SIDE CART FOOTER */}
        <div className="side-cart__footer">
          {quote && Object.keys(quote).length > 0 ? (
            <>
              <GatsbyLink
                to="/quote-cart/"
                className="action"
                aria-label="Quote cart"
                onClick={() =>
                  dispatch({ type: 'setSideCartOpen', data: false })
                }
              >
                Go to quote
              </GatsbyLink>
              <a
                className="side-cart__close-link"
                onClick={() =>
                  dispatch({ type: 'setSideCartOpen', data: false })
                }
              >
                Continue shopping
              </a>
            </>
          ) : (
            <p className="side-cart__footer__empty">
              Your quote cart is empty.
              <br />
              <GatsbyLink
                to="/products/"
                onClick={() =>
                  dispatch({ type: 'setSideCartOpen', data: false })
                }
              >
                Continue shopping
              </GatsbyLink>{' '}
              to receive a quote from us.
            </p>
          )}
        </div>
      </div>
      {children}
    </>
  )
}
