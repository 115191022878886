import React, { Component } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Logo } from '../../../theme/Logo'
import HeaderNav from '../HeaderNav'
import { SearchIcon } from '../../images/icons/SearchIcon'
import Headroom from 'react-headroom'
import './Header.scss'
import GatsbyLink from '../GatsbyLink'
import { getUrlVars, isClient } from '../../utils/helpers'
import loadable from '@loadable/component'
import { QuoteButton } from './QuoteButton'
import { MobilePhone } from './MobilePhone'

const LoadNotice = props => {
  const { ref, isRedirect, options } = props
  if (options.siteRedirectNotice && isRedirect.ref == 'core') {
    const Notice = loadable(() => import(`../Notice/index.js`))
    return <Notice message={options.siteRedirectNotice} />
  }
  if (options.zefelRedirectNotice && isRedirect.ref == 'zefel') {
    const Notice = loadable(() => import(`../Notice/index.js`))
    return <Notice message={options.zefelRedirectNotice} />
  }
  return null
}

class Header extends Component {
  state = {
    contactActive: false,
    navActive: false,
    hoverClear: false,
    loadMobileMenu: false,
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
    if (isClient) {
      if (window.innerWidth > 1080) {
        this.setState({ loadMobileMenu: false })
      } else {
        this.setState({ loadMobileMenu: true })
      }
    }
  }

  /* Small function to remove the nav-open
   * On a window resize.
   */
  handleResize = () => {
    if (isClient) {
      if (window.innerWidth > 1080) {
        let childrenDomElements = document.querySelectorAll('.children')
        for (let i = 0; i < childrenDomElements.length; i++) {
          if (childrenDomElements[i].classList.contains('open')) {
            childrenDomElements[i].classList.remove('open')
          }
        }
        if (document.body.classList.contains('nav-open')) {
          this.toggleNav()
        }
        this.setState({ loadMobileMenu: false })
      } else {
        this.setState({ loadMobileMenu: true })
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize)
  }

  toggleNav = event => {
    const { navActive } = this.state
    //if (event) event.preventDefault();
    if (!navActive) {
      if (isClient) {
        document.body.classList.add('nav-open')
      }
      this.setState({
        contactActive: false,
        navActive: true,
      })
    } else {
      if (isClient) {
        document.body.classList.remove('nav-open')
      }
      this.setState({ navActive: false })
    }
  }

  closeAll = event => {
    if (event) event.preventDefault()
    if (isClient) {
      document.body.classList.remove('nav-open')
    }
    this.setState({
      navActive: false,
    })
  }

  resetHoverClear = (event, value) => {
    this.setState({ hoverClear: true, navActive: false })
    if (isClient) {
      document.body.classList.remove('nav-open')
    }
    setTimeout(() => {
      this.setState({ hoverClear: false })
    }, 1000)
  }

  render() {
    const { navActive, hoverClear, loadMobileMenu } = this.state
    const { data, location } = this.props
    const isRedirect = getUrlVars()
    const {
      abovePrimaryNavigation,
      primaryNavigation,
      categoryNavigation,
      siteSettings,
      metaData,
      wordpressWpSettings,
    } = data
    const { options } = siteSettings
    const { phone } = options.locations[0]
    const { baseUrl } = wordpressWpSettings
    const { items: navItems } = primaryNavigation
    const { items: aboveNavItems } = abovePrimaryNavigation
    return (
      <>
        <Headroom disableInlineStyles={true} pinStart={5}>
          <div className="above-header">
            <div className="inner">
              {!loadMobileMenu && (
                <HeaderNav
                  active={navActive}
                  className="above-head-navigation"
                  navItems={aboveNavItems}
                  baseUrl={baseUrl}
                  toggleNav={this.toggleNav}
                  resetHoverClear={this.resetHoverClear}
                  hoverClear={hoverClear}
                />
              )}
            </div>
          </div>

          <div className="header-main">
            <div className="wrap">
              <div className="inner">
                <Logo />
                <div className="nav">
                  <nav className={`nav-inner${navActive ? ' active' : ''}`}>
                    <HeaderNav
                      active={navActive}
                      className="navigation"
                      resetHoverClear={this.resetHoverClear}
                      hoverClear={hoverClear}
                      categoryNavigation={categoryNavigation}
                      navItems={navItems}
                      baseUrl={baseUrl}
                      toggleNav={this.toggleNav}
                    />
                    {loadMobileMenu && (
                      <HeaderNav
                        active={navActive}
                        className="above-head-navigation-mobile"
                        resetHoverClear={this.resetHoverClear}
                        hoverClear={hoverClear}
                        navItems={aboveNavItems}
                        baseUrl={baseUrl}
                        toggleNav={this.toggleNav}
                      />
                    )}
                  </nav>
                  <div className="controls">
                    <MobilePhone />
                    <QuoteButton />
                    <GatsbyLink
                      className={`icon-search`}
                      to="/search/"
                      aria-label="Search"
                    >
                      <span className="icon icon-Search_48x48" />
                    </GatsbyLink>
                    <button
                      type="button"
                      className={`control-nav icon-hamburger ${
                        navActive ? 'active' : ''
                      }`}
                      onClick={this.toggleNav}
                      aria-label="Toggle Menu"
                    >
                      <span className={`burger ${navActive ? 'active' : ''}`} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Headroom>
        <LoadNotice isRedirect={isRedirect} options={options} />
      </>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        abovePrimaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "above-primary-navigation" }
        ) {
          items {
            title
            url
            classes
            object_id
            slug: object_slug
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        primaryNavigation: wordpressWpApiMenusMenusItems(
          slug: { eq: "primary-navigation" }
        ) {
          items {
            title
            url
            classes
            slug: object_slug
            object_id
            target
            children: wordpress_children {
              title
              url
              classes
              object_id
              slug: object_slug
              target
            }
          }
        }
        categoryNavigation: allWordpressWpProductCategory {
          edges {
            node {
              wordpress_id
              description
              acf {
                title
                menuDescription
                featureImage {
                  source_url
                  localFile {
                    childImageSharp {
                      fixed(width: 260) {
                        ...GatsbyImageSharpFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
          }
        }
        siteSettings: wordpressAcfOptions(options: {}) {
          options {
            siteRedirectNotice
            zefelRedirectNotice
            quotePage
            locations {
              addressDisplay
              email
              phone
            }
          }
        }
        wordpressWpSettings {
          siteUrl
        }
      }
    `}
    render={data => <Header data={data} {...props} />}
  />
)
