import React from 'react'
import Footer from '../components/Footer'
import '../sass/global/styles.scss'
import Header from '../components/Header'
import { GlobalHeaderStateProvider } from '../components/SideCart/SideCartContext'
import { SideCart } from '../components/SideCart'

const DefaultLayout = ({ children, location }) => {
  return (
    <div id="layout">
      <GlobalHeaderStateProvider>
        <Header location={location} />
        <SideCart>
          <div className="main">{children}</div>
        </SideCart>
        <Footer location={location} />
      </GlobalHeaderStateProvider>
    </div>
  )
}

export default ({ children, location }) => {
  return <DefaultLayout children={children} location={location} />
}
