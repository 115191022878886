import React from 'react'
import QuoteCartCount from '../Quote/QuoteCartCount'
import { useHeaderState } from '../SideCart/SideCartContext'

export const QuoteButton = () => {
  const {
    dispatch,
    state: { menuOpen },
  } = useHeaderState()
  return (
    <div
      className="quote-cart"
      onClick={() => dispatch({ type: 'setSideCartOpen', data: !menuOpen })}
    >
      <span className="cart-text">My Quote Cart</span>
      <span className="icon icon-Cart_48x48" />
      <QuoteCartCount />
    </div>
  )
}
