import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

export const PassSocialLinks = (props) => {
  const { data } = props
  const { siteSettings } = data
  const { socialLinks } = siteSettings.options
  return (
    <div className="social-links">
      Follow us:
      <ul>
        {socialLinks && socialLinks.map((item, index) => (
          <li key={index} >
            <a
              className={item.type}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={item.type}
            >
             {item.type === 'facebook' && 'Facebook'}
             {item.type === 'twitter' && 'Twitter'}
             {item.type === 'instagram' && 'Instagram'}
             {item.type === 'youtube' && 'YouTube'}
             {item.type === 'linkedin' && 'LinkedIn'}
             {item.type === 'pinterest' && 'Pinterest'}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export const SocialLinks = (props) => {
  return (
    <StaticQuery
      //Look to update this query when dynamic variables become available https://spectrum.chat/gatsby-js/general/using-variables-in-a-staticquery~abee4d1d-6bc4-4202-afb2-38326d91bd05
      query={graphql`
        query {
          siteSettings: wordpressAcfOptions(options: {}) {
            options {
              socialLinks {
                type
                url
              }
            }
          }
        }
      `}
      render={data => <PassSocialLinks {...props} data={data} />}
    />
  );
}
