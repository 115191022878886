import { graphql, useStaticQuery } from 'gatsby'

export const useAllProducts = () => {
  const { products } = useStaticQuery(
    graphql`
      {
        products: allWordpressWpProducts {
          edges {
            node {
              wordpress_id
              title
              path
              acf {
                colours {
                  colourHex
                  colourImage {
                    source_url
                  }
                  colourName
                }
              }
              featured_image_url {
                source_url
              }
            }
          }
        }
      }
    `
  )
  return products
}
