import React from 'react';
import GatsbyLink from '../src/components/GatsbyLink';

export const Logo = props => {
  return (
    <GatsbyLink to="/" className="logo-footer" aria-label="Home">
    <svg
      width={131}
      height={131}
      viewBox="0 0 131 131"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M65.5 0C101.675 0 131 29.325 131 65.5S101.675 131 65.5 131 0 101.675 0 65.5 29.325 0 65.5 0zM40.59 74.629c-4.77-.002-8.64 3.916-8.642 8.75-.001 4.834 3.864 8.754 8.635 8.757 4.771.001 8.64-3.916 8.642-8.75.002-4.835-3.864-8.756-8.635-8.757zm55.651-37.332c-.196.238-.322.38-.436.532-4.388 5.874-8.779 11.745-13.147 17.633-.214.288-.324.726-.324 1.095-.017 11.555-.014 23.11-.012 34.666 0 .243.02.485.031.727l13.888-.506V37.297zm-48.684-1.28c-4.068 2.372-8.376 3.871-12.967 5.054L62.353 83.27l8.645-11.541-23.44-35.712z"
        fill="#59C461"
        fillRule="evenodd"
      />
    </svg>
    </GatsbyLink>
  );
}
