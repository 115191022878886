import React from 'react'
import { Link } from 'gatsby'
import { edgeTest, decodeEntities, trailingSlash } from '../../utils/helpers'

export default class GatsbyLink extends React.Component {
  scrollIntoView = (event, containerId) => {
    if (typeof window !== 'undefined') {
      event.preventDefault()
      containerId = containerId.replace('#', '')
      const results = document.getElementById(containerId)
        ? document.getElementById(containerId)
        : null
      results && results.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }

  render() {
    const {
      to,
      activeClassName = 'active',
      children,
      data,
      decode = false,
      partiallyActive,
      ...other
    } = this.props

    if (to && to.includes('#') && to.charAt(0) === '#') {
      return (
        <span onClick={event => this.scrollIntoView(event, to)} {...other}>
          {decode ? decodeEntities(children) : children}
        </span>
      )
    }

    if (to && to.includes('#') && to.charAt(0) !== '#') {
      return (
        <Link
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          to={to}
        >
          {decode ? decodeEntities(children) : children}
        </Link>
      )
    }

    if (to && to.startsWith('/') && edgeTest()) {
      return (
        <Link
          activeClassName={activeClassName}
          partiallyActive={partiallyActive}
          {...other}
          to={trailingSlash(to)}
        >
          {decode ? decodeEntities(children) : children}
        </Link>
      )
    }

    return (
      <a href={to} {...other}>
        {decode ? decodeEntities(children) : children}
      </a>
    )
  }
}
