import React from 'react';
import ls from 'local-storage'

const isClient = typeof window !== 'undefined';

export default class QuoteCartIcon extends React.Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      quote: [],
    }
  }

  componentDidMount() {
    this._isMounted = true;
    this.interval = setInterval(() => this.setState({ quote: ls.get('quote') || [] }), 2000);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval)
    this.setState({ quote: [] })
  }

  render() {
    if (this.state.quote.length > 0) {
      return <span className="count">{this.state.quote.length}</span>
    }else{
      return null
    }
  }
}
