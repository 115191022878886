// taken from Oban's navigation logic
import React from 'react'

const globalStateContext = React.createContext(undefined)

function globalStateReducer(state, action) {
  switch (action.type) {
    case 'setSideCartOpen': {
      if (state.menuOpen !== action.data) {
        document.body.classList.toggle('cart-open')
        return { menuOpen: action.data }
      }
      break
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const GlobalHeaderStateProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(globalStateReducer, {
    menuOpen: false,
  })
  const value = { state, dispatch }
  return (
    <globalStateContext.Provider value={value}>
      {children}
    </globalStateContext.Provider>
  )
}

const useHeaderState = () => {
  const context = React.useContext(globalStateContext)
  if (context === undefined) {
    console.log('There was an error getting the global state context')
  }
  return context
}

export { GlobalHeaderStateProvider, useHeaderState }
