import React from 'react';
import { decodeEntities } from '../../utils/helpers';

 const RenderContent = (props) => {
  const { content = '', excerpt = false, data, className = "entry-content"} = props;
  const { render_content = excerpt ? content.split('<!--more-->')[0] : content } = content;
  var new_content = render_content ? render_content.replace('<iframe', '<div class="outer-video"><div class="video"><iframe') : '';
  var new_content = new_content ? new_content.replace('</iframe>', '</iframe></div></div>') : '';
  return (
    <div
        className={className}
        dangerouslySetInnerHTML={{__html: decodeEntities(new_content)}}
    />
  )
}

export default RenderContent
